<template>
    <div class="f1 apps-page w-full dF fC f1 px-4">
        <div class="dF aC">
            <div class="dF aC f1">
                <h3>Team</h3>
            </div>
        </div>
        <a-card :bodyStyle="{ padding: 0 }" class="my-3">
            <div>
                <a-table
                    :rowKey="(e) => e.key"
                    :columns="columns"
                    :dataSource="users"
                    :loading="loading"
                    :scroll="{ x: 1350 }"
                >
                    <div slot="name" slot-scope="user" class="dF aC">
                        <div class="mr-3">
                            <div
                                class="air__utils__avatar"
                                v-if="user && user.avatar"
                            >
                                <img :src="user.avatar" alt="User" />
                            </div>
                            <a-avatar
                                v-else
                                shape="circle"
                                size="large"
                                :class="$style.avatar"
                                >{{
                                    getInitial(user.firstName, user.lastName)
                                }}</a-avatar
                            >
                        </div>
                        {{ user.firstName + " " + user.lastName }}
                    </div>
                    <div slot="role" slot-scope="user">
                        <a-dropdown style="cursor: pointer">
                            <div class="ant-dropdown-link">
                                {{
                                    user.adminRole === "superadmin"
                                        ? "Super Admin"
                                        : "Admin"
                                }}<a-icon class="ml-3" type="down" />
                            </div>
                            <a-menu
                                slot="overlay"
                                @click="changeRole(user, $event)"
                            >
                                <a-menu-item
                                    v-for="role in roles"
                                    :key="role.id"
                                >
                                    <span>{{ role.name }}</span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                    <div
                        slot="createdAt"
                        slot-scope="user"
                        style="color: var(--med-gray)"
                    >
                        {{ getDate(user.createdAt) }}
                    </div>
					<div
                        slot="email"
                        slot-scope="user"
                    >
                        {{ user.email }}
                    </div>
                    <div
                        slot="updatedAt"
                        slot-scope="user"
                        style="color: var(--med-gray)"
                    >
                        {{ getDate(user.updatedAt) }}
                    </div>
                    <div slot="industry" slot-scope="user">
                        {{ user.industry }}
                    </div>
                    <div
                        slot="actions"
                        slot-scope="user"
                        class="dF aC"
                        style="gap: 20px; font-size: 24px"
                    >
                        <svg
                            v-if="!user.owner"
                            @click="revokeAccess(user)"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2 action-buttons"
                            style="color: #cf2d38; cursor: pointer"
                        >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </div>
                </a-table>
            </div>
        </a-card>
        <a-modal
            v-model="createNewSuperAdmin"
            ok-text="SEND"
            @ok="addUpdateSuperAdmin"
            @cancel="closeUser"
            centered
            :width="'50%'"
        >
            <h4 class="mb-3">Add New Super Admin</h4>
            <hr style="margin-left: -24px; margin-right: -24px" />

            <a-form>
                <vue-custom-scrollbar style="min-height: 250px; width: 100%">
                    <template v-for="(person, personI) in inviteList">
                        <a-row :gutter="16" :key="person + personI">
                            <a-col :span="12">
                                <a-form-item
                                    required
                                    v-if="personI === 0"
                                    label="Email Address"
                                >
                                    <a-input
                                        v-model="person.email"
                                        placeholder="Email address"
                                        required
                                    />
                                </a-form-item>
                                <a-input
                                    style="margin-bottom: 24px"
                                    v-else
                                    v-model="person.email"
                                    placeholder="Email address"
                                    required
                                />
                            </a-col>
                            <a-col :span="11">
                                <a-form-item
                                    required
                                    v-if="personI === 0"
                                    label="Role"
                                >
                                    <a-select v-model="person.role" required>
                                        <template
                                            v-for="(role, roleI) in roles"
                                        >
                                            <a-select-option
                                                :value="role.id"
                                                :key="roleI"
                                            >
                                                <template>
                                                    {{ role.name }}
                                                </template>
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                </a-form-item>
                                <a-select v-else v-model="person.role" required>
                                    <template v-for="(role, roleI) in roles">
                                        <a-select-option
                                            :value="role.id"
                                            :key="roleI"
                                        >
                                            <template
                                                v-if="role.name === 'bh_admin'"
                                            >
                                                Admin
                                            </template>
                                            <template v-else>
                                                {{ role.name }}
                                            </template>
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-col>
                            <a-col
                                :span="1"
                                v-if="personI !== 0"
                                style="margin-top: 7px"
                            >
                                <svg
                                    @click="inviteList.splice(personI, 1)"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="
                                        feather feather-trash-2
                                        action-buttons
                                    "
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                    ></path>
                                    <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                    ></line>
                                    <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                    ></line>
                                </svg>
                            </a-col>
                        </a-row>
                    </template>
                </vue-custom-scrollbar>
            </a-form>

            <div
                style="color: var(--orange); cursor: pointer; width: 120px"
                @click="
                    roles.length && roles[0].id
                        ? inviteList.push({ email: '', role: `${roles[0].id}` })
                        : inviteList.push({ email: '', role: '' })
                "
            >
                <a-icon
                    type="plus-circle"
                    style="color: var(--orange)"
                    class="mr-2"
                />
                Add another
            </div>

            <template slot="footer">
                <a-button
                    style="width: 100px"
                    class="cancel-button"
                    @click="closeUser"
                >
                    CANCEL
                </a-button>
                <a-button
                    style="width: 100px"
                    type="primary"
                    :loading="loading"
                    @click="addUpdateSuperAdmin"
                >
                    Submit
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";
import moment from "moment";
import { getInitial } from "bh-mod";

export default {
    components: {
        vueCustomScrollbar,
    },
    data() {
        return {
            users: [],
            loading: false,
            confirmLoading: false,
            selectedUser: {},
            inviteList: [],
            roles: [
                { name: "Super Admin", id: "superadmin" },
                { name: "Admin", id: "admin" },
            ],
        };
    },
    computed: {
        createNewSuperAdmin: {
            get() {
                return this.$store.state.addNew["superAdmin"];
            },
            set() {
                this.UPDATE_ADD_NEW({
                    key: "superAdmin",
                    value: false,
                });
            },
        },

        columns() {
            return [
                {
                    title: "Admin Name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                    sorter: (a, b) => {
                        const fullNameA = a.firstName + a.lastName;
                        const fullNameB = b.firstName + b.lastName;
                        return fullNameA < fullNameB
                            ? -1
                            : fullNameA > fullNameB
                            ? 1
                            : 0;
                    },
                },
                {
                    title: "Role",
                    key: "role",
                    scopedSlots: { customRender: "role" },
                    sorter: (a, b) =>
                        a.adminRole < b.adminRole
                            ? -1
                            : a.adminRole > b.adminRole
                            ? 1
                            : 0,
                },
				{
                    title: "Email Address",
                    key: "email",
                    scopedSlots: { customRender: "email" },
					sorter: (a, b) =>
                        a.email < b.email
                            ? -1
                            : a.email > b.email
                            ? 1
                            : 0,
                },
                {
                    title: "Account Created",
                    key: "createdAt",
                    scopedSlots: { customRender: "createdAt" },
                    sorter: (a, b) =>
                        moment(a.createdAt).format("x") -
                        moment(b.createdAt).format("x"),
                },
                {
                    title: "Account Updated",
                    key: "updatedAt",
                    scopedSlots: { customRender: "updatedAt" },
                    sorter: (a, b) =>
                        moment(a.updatedAt).format("x") -
                        moment(b.updatedAt).format("x"),
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                },
            ];
        },
    },

    watch: {
        createNewSuperAdmin(val) {
            if (val) {
                if (this.roles.length && this.roles[0].id)
                    this.inviteList = [
                        { email: "", role: `${this.roles[0].id}` },
                    ];
                else this.inviteList = [{ email: "", role: "" }];
            }
        },
    },

    created() {
        this.getUsers();
    },

    methods: {
        ...mapMutations(["UPDATE_ADD_NEW"]),

        getInitial,

        async getUsers() {
            this.loading = true;
            try {
                let { data } = await this.$api.get(`/super-admin/admin-users`);
                this.users = data.map((u, index) => {
                    return {
                        ...u,
                        key: index,
                    };
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching user list. Please try again!"))
				}
            }
            this.loading = false;
        },

        getDate(date) {
            return moment(date).format("D/M/YYYY");
        },

        mailUser(user) {
            this.$router.push(`/users/${user.id}`);
        },

        addUpdateSuperAdmin() {
            this.loading = true;

            this.inviteList = this.inviteList.filter((i) => i.email && i.role);

            this.$api
                .post("/super-admin/admin-users/add", {
                    users: this.inviteList,
                })
                .then(() => {
                    this.$message.success(
                        "Successfully Add/Updated Admin Role"
                    );
                    this.createNewSuperAdmin = false;
                    this.loading = false;
                    this.inviteList = [];
                    this.getUsers();
                })
                .catch((err) => {
                    this.loading = false;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while performing action on admin role. Please try again!"));
					}
                });
        },

        revokeAccess(user) {
            this.selectedUser = user;
            const self = this;
            this.$confirm({
                title: "Revoke Access",
                content: (h) => (
                    <div>
                        Do you want to revoke access to{" "}
                        <b>{self.selectedUser.email}?</b>
                    </div>
                ),
                okText: "REVOKE",
                okType: "danger",
                cancelText: "CANCEL",
                centered: true,
                onOk() {
                    self.confirmRevoke(self.selectedUser.id);
                },
                onCancel() {
                    self.selectedUser = {};
                },
            });
        },

        confirmRevoke(id) {
            this.$api
                .delete(`/super-admin/admin-users/revoke/${id}`)
                .then(({ data }) => {
                    this.$message.success(
                        "Successfully Revoked Access to " +
                            this.selectedUser.firstName +
                            " " +
                            this.selectedUser.lastName +
                            "!",
                        3
                    );
                    this.selectedUser = {};
                    this.getUsers();
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while removing user access. Please try again!"));
					}
                });
        },

        closeUser() {
            this.createNewSuperAdmin = false;
            this.inviteList = [];
        },

        changeRole(user, { key }) {
            const changedRole = this.roles.find((x) => x.id === key).id;

            if (changedRole === user.adminRole) {
                return;
            }
            this.selectedUser = user;

            this.inviteList = [{ email: user.email, role: changedRole }];

            let self = this;
            this.$confirm({
                title: "Change Role",
                content: (h) => (
                    <div>
                        Are you sure you want to change this role?
                    </div>
                ),
                okText: "CONFIRM",
                okType: "danger",
                cancelText: "CANCEL",
                centered: true,
                onOk() {
                    self.addUpdateSuperAdmin();
                },
                onCancel() {
                    self.inviteList = [];
                    self.selectedUser = {};
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss">
.ps--active-x > .ps__rail-x {
    display: none;
}

li.ant-dropdown-menu-item {
    height: max-content;
    line-height: 30px;
}
li.ant-dropdown-menu-item:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}

body .ant-dropdown-menu-item {
    padding: 5px 10px;
    background-color: white;
    border-radius: 20px;
}
</style>
